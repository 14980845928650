.Header {
  height: $header-height;
  position: fixed;
  display: flex;
  align-items: stretch;
  justify-content: flex-end;
  top: 0;
  right: 0;
  left: $sidebar-width;
  background-color: white;
  box-shadow: 0px 0px 40px 0px rgba(82,63,105,0.1);
}

.Header__actions {
  height: 100%;
  display: flex;
  align-items: stretch;

  a {
    display: block;
    height: 100%;
    color: $gray-600;
  }
}

.Header__actionItem {
  border-left: 1px solid $gray-300;

  &,
  > a {
    height: 100%;
    display: flex;
    align-items: center;
  }

  a:hover {
    text-decoration: none;
    color: $blue;
  }

  &.logout {
    a {
      padding: 0 1rem;
    }
  }
}
