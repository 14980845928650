.Sidebar {
  width: $sidebar-width;
  background-color: $sidebar-bg;
  color: white;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
}

.Sidebar__header {
  height: $header-height;
  padding: 0 1rem;
  display: flex;
  align-items: center;
}

.Sidebar__body {
  padding-top: 2rem;
}

.Sidebar__logo {
  font-family: Lobster;
  font-size: 1.75rem;

  span {
    position: relative;
    top: -10px;
    font-size: 0.8rem;
  }
}

.Sidebar__links {
  padding: 0;
  margin: 0;
  list-style: none;
}

.Sidebar__link {
  padding: 0.75rem 1rem;
  display: flex;
  align-items: center;
  color: #c0c4d1;
  @include font-size(0.95rem);

  i {
    width: 30px;
    color: #434d6b;
  }

  span {
    flex-grow: 1;
  }

  &:hover {
    color: white;
    text-decoration: none;
    background-color: $sidebar-link-hover-bg;

    i {
      color: $blue;
    }
  }

  &.active {
    background-color: $sidebar-link-hover-bg;
    color: white;
  }
}
