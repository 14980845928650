.LoginPage {
  width: 100%;
  min-height: 100%;
  top: 0;
  left: 0;
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  color: white;
  background: url('https://lh3.googleusercontent.com/3DRd77XPjl_ZsD4xrEuw1pQ4JC3sLEerdSjdQZEn1KcL_Mxr-J_OHOXGwJvv9f7EWTVKZ0__fKO_6ud8Hp5HYw=w1440') center center no-repeat;
  background-size: cover;
}

.LoginPage__wrap {
  width: 100%;
  min-height: 100%;
  top: 0;
  left: 0;
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.LoginPage__logo {
  color: var(--gray-dark);
  font-family: 'Lobster';
  font-size: $h3-font-size;

  @include media-breakpoint-up(md) {
    font-size: 2.25rem;
  }

  span {
    position: relative;
    top: -12px;
    font-size: 1.25rem;
  }
}

.LoginPage__modal {
  margin-top: 1.5rem;
  width: 350px;
  padding: 1.5rem;
  background-color: white;
  border-radius: $border-radius;
  box-shadow: 0 0 5px 3px rgba(0,0,0,0.1);
}
