html,
body {
  height: 100%;
  background-color: $bg;
  display: flex;
}

body,
#root {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
}
