label.required {
  &:after {
    display: inline-block;
    padding-left: 0.25rem;
    content: '*';
    color: $danger;
  }
}

.input-group.is-invalid ~ .invalid-feedback {
  display: block;
}