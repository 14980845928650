$blue: #5578eb;
$success: #1dd1a1;
$danger: #fd397a;

$bg: #f2f3f8;

$gray-200:                  lighten($blue, 33%);
$gray-300:                  lighten($blue, 27%);

$yiq-contrasted-threshold:    195;

$font-family-sans-serif: 'Poppins', sans-serif;
$font-weight-normal:          300 !default;
$font-weight-bold:            500 !default;
$font-weight-bolder:          700 !default;

$sidebar-width: 260px;
$sidebar-bg: #242939;
$sidebar-link-hover-bg: #1f2433;

$header-height: 75px;

$font-family-sans-serif: Roboto, -apple-system, BlinkMacSystemFont, "Segoe UI", "Helvetica Neue", Arial, sans-serif !default;

$border-radius:               .2rem !default;
$border-radius-lg:            .25rem !default;
$border-radius-sm:            .15rem !default;

$input-btn-focus-width:       .1rem !default;


$table-head-color:            #646c9a !default;
$table-border-color:            $gray-200;
